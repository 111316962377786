<template>
  <div class="thanksPatronize">
    <img class="bg" src="@/assets/thanksPatronize/thanks.png" alt="" />
    <div class="content">
      <div class="tip">
        <p>下次继续努力</p>
        <p>祝您好运~</p>
      </div>
      <div class="bottom">
        <moreGift></moreGift>
      </div>
    </div>
  </div>
</template>

<script>
import moreGift from '@/components/moreGift/index.vue'
export default {
  name: 'thanksPatronize',
  components: {
    moreGift
  },
  data() {
    return {}
  },
  methods: {
    returnBtn() {
      this.$router.go(-1)
    }
  },
  // 只要不是从转盘页面来的说明是从电子奖品回来的，需要重定向
  beforeRouteEnter(to, from, next) {
    // 从转盘过来的
    if (sessionStorage.getItem('isFromTurntable')) {
      sessionStorage.removeItem('isFromTurntable')
      sessionStorage.setItem('showedThanksPatronize', true) //标记谢谢惠顾页面已经展示过了，用于返回转盘时，转盘页面获取，由于谢谢惠顾是不允许返回到转盘页面的，可以作为转盘页面重定向的依据

      next()
    } else {
      // 从电子奖品返回的
      sessionStorage.setItem('thanks', true)
      sessionStorage.removeItem('showedThanksPatronize') //防止携带back 11值
      next({
        path: '/codeUsed',
        query: { isNoBack: true } //标记重定向进去后如果再次抽奖不用传递back值
      })
    }
  }
}
</script>

<style scoped lang="less">
.thanksPatronize {
  width: 100%;
  height: 100%;
  background: #ABB776;
  position: relative;
  .bg {
    width: 100%;
  }
  .content {
    position: absolute;
    top: 801px;
    width: 100%;
    .tip {
      width: 100%;
      p {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 400;
        color: #fcee98;
        line-height: 51px;
      }
    }
    .bottom {
      margin-top: 210px;
    }
  }
}
</style>
